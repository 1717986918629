
import React,{useEffect,useState} from 'react'
import { Routes, Route, Link, Navigate } from "react-router-dom"
import PatientRoute from './PatientRoute'
// PAGES
import Card from '../Component/Card/Card'
import NotApproved from '../Component/NotApproved/Card'

// PUBLIC PAGES
import SignUp from '../Pages/signup/Signup'
import SignIn from '../Pages/SignIn/SignIn'
import DocSignIn from "../Pages/DoctorSignIn/SignIn"
import BusSignIn from "../Pages/BusSignIn/SignIn"
import BusSignUp from "../Pages/BusSignUp/SignUp"
import NssSignUp from "../Pages/NssSignUp/SignUp"
import About from '../Pages/Settings/About'
import LandingPage from '../Pages/landingPage/LandingPage';
import TermsandCondition from '../Pages/Settings/TermsandCondition'
import ChangePassword from '../Pages/ChangePassword/ChangePassword'
import LoginOptions from "../Pages/LoginOption/options"
import SignUpOptions from "../Pages/SignUpOption/options"
import Navbar from '../Component/Navbar/Navbar'
import Availability from '../DoctorPages/Availability/Availability'
import AboutClaron from '../Pages/AboutClaron/AboutClaron'

function Index() {

    return (
        <div>
            <Routes>
                
                <Route exact path="/userDashboard" element={<NotApproved/>} />

                {/* PUBLIC PAGES */}
                 
                <Route exact path="/" element={<LandingPage />}></Route>
                <Route exact path="/LoginOptions" element={<LoginOptions />}></Route>
                <Route exact path="/SignUpOptions" element={<SignUpOptions />}></Route>
                <Route exact path="/SignIn" element={<SignIn/>}></Route>
                <Route exact path="/DocSignIn" element={<DocSignIn/>}></Route>
                <Route exact path="/BusSignIn" element={<BusSignIn/>}></Route>
                <Route exact path="/BusSignUp" element={<BusSignUp/>}></Route>
                <Route exact path="/SignUp" element={<SignUp />}></Route>
                <Route exact path="/NssSignup" element={<NssSignUp />}></Route>
                <Route exact path="/about" element={<About />}></Route>
                <Route exact path="/privacy" element={<TermsandCondition />}></Route>
                
           </Routes>
        </div>
    )
}

export default Index
