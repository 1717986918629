import React from "react";
import "./options.css";
import { useNavigate } from "react-router-dom";
import doctorDefault from '../../images/doctor-login.png'
import claronPatient from '../../images/examination.png'
import claronBusiness from '../../images/business.jpg'
import Navbar from '../../Component/Navbar/Navbar'
import { FaArrowRight } from "react-icons/fa";

function Options() {
const navigate = useNavigate()
    const setType = (type) => {
        if(type === "patient"){
            navigate("/SignUp") 
            localStorage.setItem("type",type)
           
        }else{
            navigate("/BusSignUp") 
            localStorage.setItem("type",type)
        }
    }
        return (
            <>
                <Navbar />
               
                    <div class="options-card-container">
                        <div onClick={() => setType("patient")} class="options-card-box-history">
                        <img src={claronPatient} alt="" />
                        <button className="login-options">Sign up As Patient <FaArrowRight/></button>
                        </div>

                        <div onClick={() => setType("business")} class="options-card-box-history">
                        <img src={claronBusiness} alt="" />
                        <button className="login-options">Sign Up As Business <FaArrowRight/></button>
                    </div>
                </div>
                    
            </>
        
  );
}

export default Options;
