export const Patients =[
    {
        id:1,
        name:"Ravers Sappor",
        email: "rasper@gmail.com",
        phone: "0244380000"
    },
    {
        id:2,
        name:"James tukur",
        email: "james@gmail.com",
        phone: "0244388000"
    },
    {
        id:3,
        name:"Vincent Chibuike",
        email: "vincent@gmail.com",
        phone: "0744380000"
    },
    {
        id:4,
        name:"Spunky Henry",
        email: "henry@gmail.com",
        phone: "0244380076"
    },
    {
        id:5,
        name:"Alex Philip",
        email: "alex@gmail.com",
        phone: "0244380054"
    },
    {
        id:6,
        name:"Godwin Philip",
        email: "godwin@gmail.com",
        phone: "024438007654"
    },
    
]