import React, { useState, useEffect } from 'react'
import './Card.css'
import '../../Pages/Dash/Dash.css'
import { Link } from "react-router-dom"
import moment from 'moment';
import { userDetails, downgrade } from '../../Api/Auth';
// import images
import drug from '../../images/drug.png'
import labrequest from '../../images/labrequest.png'
import ambulance from '../../images/ambulance.png'
import homecare from '../../images/homecare.png'
import NotApprovedMainLayout from '../../Pages/NotApprovedMainLayout'
import { useNavigate } from "react-router-dom"
import { myBookings } from '../../Api/doctors';
import { useSelector } from 'react-redux'
import loading from '../../images/loading.gif'
import { getTokenFn } from "../../firebaseConfig";
import {firebaseApp} from "../../firebaseConfig"
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { green100, green200, green400, green900 } from 'material-ui/styles/colors';
import { green } from '@mui/material/colors';
import { BsTypeH3 } from 'react-icons/bs';

function Card({ sidebar }) {
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.value)
    const [bookings, setBookings] = useState([])
    const [expiry, setexpiry] = useState()
   

    //   CHECKING USER SUBSCRIPTION 
    const checkSubscription = async (date) => {

        if (date == null) {
            return
        }

        const days = moment().diff(date, 'days')
        const left = moment(date).add(1, 'day').fromNow()
        console.log(days, 'gggggg')
        localStorage.setItem('subscription_exp_day', days);

        if (days === 0) {
            setexpiry('Your subscription ends today. Renew now to avoid losing access to services')
        } else if (days < 0 && days > -11) {
            setexpiry(`Your subscription ends in ${left}. Renew now to avoid losing access to services`)
        } else if (days > 0 && days < 10) {
            setexpiry(`Your subscription ended ${left}. Renew now to avoid losing access to services`)
        } else if (days > 10) {
            let res = await downgrade()
            console.info(res)
            // setexpiry(`Your subscription ended ${left}. Renew now to avoid losing access to services`)
        }
    }


    useEffect(() => {


        getTokenFn().then(async(firebaseToken) => {
            try {
              localStorage.setItem("firebaseToken", firebaseToken);
            //   setToken(firebaseToken);
              await firebaseApp.firestore().collection('device_token').doc(userData.email).set({token: firebaseToken})
              // console.log(res, "rrrrr")
              // await API.updateFCMToken(firebaseToken)
            } catch (error) {
              await firebaseApp.firestore().collection('device_token').doc('error').set({token: error});
              console.log(error)
            }
          })
          .catch((err) => {
            return err;
          });


        (async () => {
            try {
                userDetails(userData.email).then(data => {
                    localStorage.setItem('subscription', data.subscription);
                    checkSubscription(data.subscription_end);
                }).catch(e => {
                    console.log('Error: ', e)
                })

                let res = await myBookings()

                setBookings(res.requests)
            } catch (error) {
                console.log(error)
            }

        })()

    }, [])



    const editPage = () => {
        navigate('/Editpatient', { state: { user: userData } });
    }

    return (
        <NotApprovedMainLayout>
        
             <h3 style={{margin: 100, color: "orange", display: 'inline-block'}}>
                Please allow us 24hours to approve your account after verifying our details. We will contact you should your approval not go through.
                You may also reach us via email: <span style={{color: "#2D69C7"}}>admin@clarondoc.com</span> 
            </h3>
             
        </NotApprovedMainLayout>
    )
}

export default Card
