import axios from "axios";

export const register = async (data) => {
  const key = await apiKey();
  try {
    const response = await axios({
      method: "POST",
      url: "http://localhost:8080/register",
      data,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    localStorage.setItem("access-token", response.data.accessToken);
    localStorage.setItem("login-expiry", response.data.tokenExpiryUTC);
    localStorage.setItem("email", data.email);
    await userDetails(data.email, key, response.data.accessToken);
    console.log(response.data);
    return response.data;
  } catch (e) {
    return {
      message: e.response.data.message, //'The credentials you provided are not associated with any account',
      success: false,
    };
  }
};

export const resetpassword = async (email) => {
  const key = await apiKey();

  try {
    const response = await axios({
      method: "GET",
      url: "http://localhost:8080/otp/get/" + email,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return response.data;
  } catch (e) {
    return {
      message: e.message, //'The credentials you provided are not associated with any account',
      success: false,
    };
  }
};

// OTP

export const checkotp = async (reset_code) => {
  const key = await apiKey();

  try {
    const response = await axios({
      method: "GET",
      url: "http://localhost:8080/otp/confirm/" + reset_code,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return response.data;
  } catch (e) {
    return {
      message: e.message, //'The credentials you provided are not associated with any account',
      success: false,
    };
  }
};

// CHANGE PASSWORD
export const changePasswordd = async (data, auth) => {
  const key = await apiKey();
  // const auth = await AsyncStorage.getItem('access-token');
  const response = await axios.default.put(
    "http://localhost:8080/users/update/password",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${auth}`,
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  console.log(response.data);
  return response.data;
};

// UPDATE PASSWORD
export const updatePassword = async (data) => {
  const key = await apiKey();
  const auth = localStorage.getItem("access-token");
  const response = await axios.default.put(
    "http://localhost:8080/users/update/password",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${auth}`,
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  console.log(response.data);
  return response.data;
};

export const update = async (data) => {
  const key = await apiKey();
  const auth = localStorage.getItem("access-token");
  const response = await axios.default.put(
    "http://localhost:8080/users/update/profile",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${auth}`,
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  return response.data;
};

// PATIENT LOGIN
export const login = async (email, password) => {
  const key = await apiKey();
  try {
    const response = await axios({
      method: "POST",
      url: "http://localhost:8080/login",
      data: {
        email,
        password,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        "x-api-key": key,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    console.log(response.data, "response")
    localStorage.setItem("access-token", response.data.accessToken);
    localStorage.setItem("login-expiry", response.data.tokenExpiryUTC);
    localStorage.setItem("email", email);
    const userInfo = await userDetails(email, key, response.data.accessToken);
    localStorage.setItem("user", JSON.stringify(userInfo));
    console.log(userInfo, "wake up");
    return response.data;
  } catch (e) {
    return {
      message: e.response.data.message, //'The credentials you provided are not associated with any account',
      success: false,
    };
  }
};

// GETTING USER DETAILS
export const userDetails = async (email) => {
  const key = await apiKey();
  const auth = localStorage.getItem("access-token");
  const response = await axios({
    method: "GET",
    url: "http://localhost:8080/users/" + email,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": true,
      Authorization: `Bearer ${auth}`,
      "x-api-key": key,
    },
    options: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  if (response.data.success) {
    localStorage.setItem("user", JSON.stringify(response.data.userDetails));
    return response.data.userDetails;
  }
};

export const downgrade = async () => {
  const key = await apiKey();
  const auth = await localStorage.getItem("access-token");

  try {
    const response = await axios.default.put(
      "http://localhost:8080/subscriptions/downgrade",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${auth}`,
          "x-api-key": key,
        },
        options: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (e) {
    console.info(e.response.data);
    return null;
  }
};

export const apiKey = async (data) => {
  let key = localStorage.getItem("api-key");

  if (key != null) {
    return key;
  } else {
    const response = await axios({
      method: "POST",
      url: "http://localhost:8080/getAPIKey",
      data: {
        email: "developer@clarondoc.com",
        password: "Basket012Ball",
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
      },
    });
  
    key = response.data.apiKey;

    localStorage.setItem("api-key", key);
    return key;
  }
};

// SOCIAL MEDIA LOGIN
export const sociallogin = async (email) => {
  const key = await apiKey();

  try {
    const response = await axios({
      method: "POST",
      url: "http://localhost:8080/social/login",
      data: {
        email,
      },
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
      },
      options: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    console.log(key);
    localStorage.setItem("access-token", response.data.accessToken);
    localStorage.setItem("login-expiry", response.data.tokenExpiryUTC);
    localStorage.setItem("email", email);
    await userDetails(email, key, response.data.accessToken);
    await userDetails(email, key, response.data.accessToken);

    return response.data;
  } catch (e) {
    return {
      message: e.response.data.message, //'The credentials you provided are not associated with any account',
      success: false,
    };
  }
};
