import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./calls.css";
import "./General.css";
import { FaPhoneSlash, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import image from "../../images/logo.png";
import { FaPhone } from "react-icons/fa";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useLocation, useNavigate } from "react-router-dom";
import { firebaseApp } from "../../firebaseConfig";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = "13d48f193b3d45e8928d7426921708eb"; //ENTER APP ID HERE

let urgent;
let countt = 0;
let email = "samuel.anakwa@claronhealth.com";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Index() {
  const navigate = useNavigate();
  const [inCall, setInCall] = useState(false);
  const [recp, setrecp] = useState(email);
  const [picked, setpicked] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [connectionText, setConnectionText] = useState("Connecting ..");
  const [uid, setUid] = useState(0);
  const [muteAudio, setMuteAudio] = useState(false);
  const [initializing, setInitializing] = useState(false);

  const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  const handleClose = () => setOpen(false);

  let channelParameters = {
    localAudioTrack: null,
    remoteAudioTrack: null,
    remoteUid: null,
  };

  let countt_r = useRef(0);
  countt_r.current = 0;
  const email_r = useRef("");

  const doctors = [
    {
      text: "Line For Dr. Godwin",
      email: "kriegergodwin123@gmail.com",
      style: "default",
    },
  ];

  const startCall = async () => {
    // Listen for the "user-published" event to retrieve an AgoraRTCRemoteUser object.
    agoraEngine.on("user-published", async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the "user-published" event.
      await agoraEngine.subscribe(user, mediaType);
      console.log("subscribe success");

      // Subscribe and play the remote audio track.
      if (mediaType == "audio") {
        channelParameters.remoteUid = user.uid;
        // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
        channelParameters.remoteAudioTrack = user.audioTrack;
        // Play the remote audio track.
        channelParameters.remoteAudioTrack.play();
        console.log("Remote user connected: " + user.uid);
        setConnectionText("Connected");
      }

      // Listen for the "user-unpublished" event.
      agoraEngine.on("user-unpublished", (user) => {
        console.log(user.uid + "has left the channel");
        // alert("Remote user has left the channel");
        setConnectionText("Disconnected");
      });
    });
  };

  let user = localStorage.getItem("user");
  const userDetail = JSON.parse(user);

  const join = async (channelName, token) => {
    // Join a channel.
    await agoraEngine.join(appId, channelName, token, uid);
    setConnectionText("Calling ..");
    console.log("Joined channel: " + channelName);
    // Create a local audio track from the microphone audio.
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    // Publish the local audio track in the channel.
    await agoraEngine.publish(channelParameters.localAudioTrack);
    console.log("Publish success!");
  };

  const mute = async () => {
    // Create a local audio track from the microphone audio.
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    if (muteAudio) {
      // Mute the local audio.
      channelParameters.localAudioTrack.setEnabled(false);

      setMuteAudio(false);
    } else {
      channelParameters.localAudioTrack.setEnabled(true);
      setMuteAudio(true);
    }
  };

  const leaveChannel = async () => {
    // Create a local audio track from the microphone audio.
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    // Destroy the local audio track.
    channelParameters.localAudioTrack.close();
    // Leave the channel
    await agoraEngine.leave();
    console.log("You left the channel");
    setInCall(false);
    navigate(-1);
  };

  const startUrgent = async (email) => {
    setInitializing(true);

    try {
      let res = await axios.get("https://api.clarondoc.com/urgent/token");

      await firebaseApp
        .firestore()
        .collection("calls")
        .doc(email)
        .set({
          data: {
            time: new Date(),
            recipient: email,
            caller: userDetail.email,
            status: "started",
            channel: res.data.RTCChannel,
            token: res.data.RTCAccessToken,
          },
        });

      await join(res.data.RTCChannel, res.data.RTCAccessToken);
    } catch (e) {
      console.log(e);
      alert("Unable to start call", e.message);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    }
  };

  const start_now = async (email) => {
    // start
    setrecp(email);
    email_r.current = email;
    await startCall();
    await startUrgent(email);
    handleClose();

    firebaseApp
      .firestore()
      .collection("calls")
      .doc(email)
      .onSnapshot(
        async (snapshot) => {
          console.log(snapshot, "snapshotsnapshot");
          try {
            if (true) {
              urgent = snapshot.data().data;

              console.log("count:" + countt_r.current);

              if (urgent.status === "ongoing") {
                setpicked(true);
                console.log("picked");
              }

              let tokenRes = await axios.get(
                "https://api.clarondoc.com/urgent/token"
              );

              firebaseApp
                .firestore()
                .collection("device_token")
                .doc(email)
                .get()
                .then((snapshot) => {
                  console.log("Docs: ", snapshot.data());
                  let data = snapshot.data();
                  if (data) {
                    axios
                      .post(
                        "https://fcm.googleapis.com/fcm/send",
                        {
                          to: data.token,
                          notification: {
                            title: "Urgent Care",
                            sound: "ring.mp3",
                            body: "Incoming calling call",
                            subtitle: "You have a call request",
                            android_channel_id: "12345654321",
                          },
                          data: {
                            body: "call request",
                            title: "call request",
                            name: "hellworld",
                            call: {
                              name: userDetail.email,
                              time: new Date(),
                              patient: userDetail.email,
                              doctor: email,
                              caller: `${userDetail.firstname} ${userDetail.lastname}`,
                              status: "started",
                              end_now: "false",
                              channel: tokenRes.data.RTCChannel,
                              token: tokenRes.data.RTCAccessToken,
                            },
                          },
                          content_available: true,
                          priority: "high",
                        },
                        {
                          headers: {
                            Authorization: `key=AAAAEfHKSRA:APA91bH2lfkOJ8bZUGvMJo7cqdLYqk1m633KK7eu5pEaUF0J1ieFgpcWtYItCRftxVLSghEOZY5cQ8k9XfB_PVyfQeDHiC5ifuowqYUytsF0Nby4ANcZhVcFj6E0u5df2c4LItkjq4H2`,
                          },
                        }
                      )
                      .then((res) => {
                        console.log(res.data);
                      });
                  } else {
                    setConnectionText(
                      "Doctor not available now, try again in a few minutes or select another"
                    );
                  }
                  // if(snapshot.docs.length > 0){
                });

              countt_r.current += 1;
              setInitializing(false);
              setOpen(false);
              setInCall(true);
            }
          } catch (error) {}
        },
        (e) => {
          console.log("Firebase Error: ", e);
        }
      );

    // stop
  };

  const handleGoBack = () => {
    handleClose();
    navigate(-1);
  };

  return (
    <div className="out-container-call">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="call-container-modal">
          <div className="closeBtn">
            <h2 onClick={() => handleGoBack()}>X</h2>
          </div>
          <h4 className="initialize">
            {initializing ? "Initializing call... Please wait!" : null}
          </h4>
          <p className="stand-by">Stand-By Line for emergency call</p>

          {doctors.map((doc, i) => {
            return (
              <div className="doc-call-container" key={i}>
                <p
                  className="doc-btn"
                  key={doc.email}
                  onClick={() => start_now(doc.email)}
                >
                  {doc.text} <FaPhone className="call-em" />
                </p>
              </div>
            );
          })}
        </Box>
      </Modal>

      {inCall ? (
        <>
          <div className="controls">
            <div class="claron-audio-logo-container">
              <h2>Urgents Care</h2>

              <div
                style={{
                  textAlign: "center",
                  paddingTop: 56,
                  paddingBottom: 56,
                }}
              >
                <p>{connectionText}</p>
              </div>
              <img className="cal-logo-img" src={image} alt="" />
            </div>
            <div id="local_stream"></div>
            <div id="remote_stream"></div>
            <div class="inner-control">
              {!muteAudio ? (
                <button className="mute" onClick={mute}>
                  <FaVolumeMute className="mute-audio-control" />
                  Mute
                </button>
              ) : (
                <button className="mute" onClick={mute}>
                  <FaVolumeUp className="mute-audio-control" />
                  Unmute
                </button>
              )}

              {
                <button className="leav" onClick={leaveChannel}>
                  <FaPhoneSlash className="leav-icon" />
                  End Call
                </button>
              }
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Index;
