import React, { useState, useEffect } from "react";
import "./Dash/Dash.css";
import user1 from "../images/user.png";

import {
  FaCaretDown,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Dashboard/Dashboard.css";
import { useSelector, useDispatch } from "react-redux";
import { LOGOUT } from "../features/user";

export default function MainLayout({ children }) {
  const userData = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

 
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const logOut = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("access-token");
    localStorage.removeItem("api-key");
    localStorage.removeItem("login-expiry");
    localStorage.removeItem("user");
    dispatch(LOGOUT());
    navigate("/");
  };
  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      <div class="sidebar">
        <div className="profile-container">
          <img
            src={userData.avatar !== "undefined" ? userData.avatar : user1}
            alt=""
            className="user"
          />
        </div>
        <div className="responsive-title">
          <p className="name">
            {userData ? userData.firstname : ""}{" "}
            {userData ? userData.lastname : null}
          </p>
          <p className="title">Patient</p>
        </div>
      </div>

      <div class="main-content">
        <header className="main-content-toggle">
          <div className="nav-okay">
            <label for="nav-toggle">
              <span class="las la-bars"></span>
            </label>
          </div>  

          <div class="user-wrapper">
            <img
              src={userData.avatar !== "undefined" ? userData.avatar : user1}
              width="40px"
              height="40px"
              alt="user"
            />
            <div>
              <div className="responsive-mobile-profile">
                <h4 onClick={handleClick}>Profile</h4>
                <FaCaretDown />
              </div>

              {open ? (
                <div className="hover-menu">
                  <span onClick={logOut}>Logout</span>
                  {/* <h3>Home</h3> */}
                </div>
              ) : null}
              {/* <small>Super Admin</small> */}
            </div>
          </div>
        </header>
        <main>{children}</main>
      </div>
    </>
  );
}
